.t-left {
  text-align: left;
}
.t-right {
  text-align: right;
}
.pull-left,
.pull-right {
  display: block;
}

.red {
  color: $red;
}
.dark-red {
  color: darken($red,20%);
}
.green {
  color: $green;
}
.dark-green {
  color: darken($green,20%);
}
.c-orange {
  color: $orange;
}
.dark-c-orange {
  color: darken($orange,20%);
}
.grey {
  color: lightgrey;
}
.thin-view {
  color: darken(white,20%);
  font-weight: 300;
  font-size: 80%;
}
.no-top-margin {
  margin-top: 0;
}
table {
  .lp {
    width: 2rem;
    text-align: right;
  }
  .col-name {
    width: 25%;
  }
  .col-edit {
    width: 8rem;
  }
  .col-user {
    width: 10%;
  }
}
// tab wraper - for vertical middle align
.wrb-tab {
  display: table;
  width: 100%;
  height: 100%;
  .wrb-cel {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
}

.tab-horizon {
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  .tabh-items {
    width: 100%;
    .col-lab {
      font-weight: 700;
      text-transform: uppercase;
    }
    .tab-col {
      padding: 1rem;
    }
    &.border {
      border-bottom: 1px dashed lighten($text-color, 20%);
      border-right: 1px dotted lighten($text-color, 30%);
      .col-lab {
        border-bottom: 1px dashed lighten($text-color, 20%);
      }
      &:last-child{
        border-right: none;
      }
    }
  }
}