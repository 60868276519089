ol.list {
  padding: 0 0 0 25px;
  li {
    padding: 0;
    margin-bottom: 0.5rem;
  }
}
dl.order-list {
  counter-reset: my-badass-counter;
  dd {
    position: relative;
    padding-left: 3rem;
    display: block;
    &:before {
      text-align: right;
      content: counter(my-badass-counter)'.';
      counter-increment: my-badass-counter;
      position: absolute;
      left: 0;
      top: 0;
      width: 2rem;
    }
  }

}