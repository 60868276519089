$red: #F05050;
$green: #27C24C;
$yellow: #FAD733;
$orange: #f0ad4e;
@import 'variables';
@import 'utils/flex';
@import 'utils/css3-mixins';
@import 'utils/transform';
@import 'common/elements';
@import 'common/common';
@import 'park/park';
.sidebar-menu {
    li {
        border-bottom: 1px solid #fff;
        ul.sub-menu {
            list-style: none;
            background: #141F3A;
            li {
                padding: 5px 0;
                border: none;
                a {
                    padding: 12px 5px 12px 0;
                    .fa {
                        width: 20px;
                    }
                }
            }
        }
    }
}

.navbar-nav {
    .messages-menu {
        .dropdown-menu {
            li .menu {
                li {
                    a {
                        h4 {
                            margin: 0 0 0 5px !important;
                        }
                    }
                }
            }
        }
    }
}

.panel {
    .panel-body {
        .tiles {
            .sm-st {
                border: 1px solid $red;
                .sm-st-icon {
                    background: $red;
                }
            }
            .sm-st-info {
                font-size: 15px;
                padding-top: 2px;
                text-align: right;
                color: $red;
            }
        }
        .tiles:hover {
            .sm-st {
                background: $red;
                border: 1px solid $red;
                .sm-st-icon {
                    background: #fff;
                    color: $red;
                }
            }
            .sm-st-info {
                font-size: 15px;
                padding-top: 2px;
                text-align: right;
                color: #fff;
            }
        }

        table{
            th.lp,td.lp{
                width:45px;
            }
            th.option-col,td.option-col{
                text-align: right;
            }
        }
    }
}

#colorbox #cboxClose {
    top: 0;
    right: 0;
}

#cboxLoadedContent {
    margin-top: 28px;
    margin-bottom: 0;
}

#colorbox #cboxTitle {
    top: 4px;
}

#colorbox #cboxPrevious {
    top: 0px;
}

#colorbox #cboxNext {
    top: 0px;
}

#colorbox #cboxCurrent {
    top: 4px;
}

.recriut_form {
    padding-top:1%;
}

